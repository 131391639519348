import Link from 'next/link';
import { useRouter } from 'next/router';
import { Nav } from 'moralis-ui';
import { INavItemProps } from 'moralis-ui/dist/src/components/Nav/NavItem';
import { portfolioURL } from '@/components/portfolio/lib/portfolioURL';
import { useFormResetContext } from '@/helpers/hooks/formResetContext';
import { ERoutePath } from '@/utils/ERoutePath';
import { LINKS } from '@/utils/links';
import { faBullhorn, faChartPie, faCode, faCompass, faGridRound2, faHome } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface ExtendedNavItemProps extends INavItemProps {
  href?: string;
  isExternal?: boolean;
}

const MENU_ICON_SIZE = 15;

export const useAsideMenuData = (): ExtendedNavItemProps[] => {
  const router = useRouter();
  const { setTriggerReset, setTriggerResetTable } = useFormResetContext();
  return [
    {
      children: 'Home',
      icon: <FontAwesomeIcon icon={faHome} width={MENU_ICON_SIZE} />,
      id: 'home',
      href: ERoutePath.home,
      isActive:
        router.pathname === ERoutePath.home ||
        router.pathname === ERoutePath.topTokensMarketCap ||
        router.pathname === ERoutePath.newCoins,
    },
    {
      children: 'Portfolio',
      icon: <FontAwesomeIcon icon={faChartPie} width={MENU_ICON_SIZE} />,
      isActive: router.pathname.startsWith('/portfolio'),
      id: 'portfolio',
      href: portfolioURL.root,
    },
    {
      children: 'Strategy Builder',
      icon: <FontAwesomeIcon icon={faCompass} width={MENU_ICON_SIZE} />,
      id: 'strategy-builder',
      isActive: router.pathname === ERoutePath.tokenExplorer,
      href: ERoutePath.tokenExplorer,
      onClick: () => {
        setTriggerReset(true);
        setTriggerResetTable(true);
      },
    },
    {
      children: 'Advertise',
      icon: <FontAwesomeIcon icon={faBullhorn} width={MENU_ICON_SIZE} />,
      id: 'advertise',
      href: ERoutePath.marketplace,
      isActive: router.pathname === ERoutePath.marketplace,
    },
    {
      children: 'Public API Docs',
      icon: <FontAwesomeIcon icon={faCode} width={MENU_ICON_SIZE} />,
      id: 'public-api-docs',
      href: LINKS.publicAPIDocs,
      isExternal: true,
    },
    {
      children: 'More',
      icon: <FontAwesomeIcon icon={faGridRound2} width={MENU_ICON_SIZE} />,
      id: 'more',
      isActive: router.pathname === ERoutePath.pricing || router.pathname === ERoutePath.blog,
      subItems: [
        <Link key="pricing" href={ERoutePath.pricing}>
          <Nav.SubItem isActive={router.pathname === ERoutePath.pricing}>Pricing</Nav.SubItem>
        </Link>,
        <Link key="blog" href={ERoutePath.blog}>
          <Nav.SubItem key="blog" isActive={router.pathname === ERoutePath.blog}>
            Blog
          </Nav.SubItem>
        </Link>,
        <Link key="affiliates" href={LINKS.affiliate} target="_blank">
          <Nav.SubItem key="Affiliates">Affiliates</Nav.SubItem>
        </Link>,
      ],
    },
  ];
};
