import { ButtonOutline, Input, Tooltip } from 'moralis-ui';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './AsideSearchInput.module.scss';

export const AsideSearchInput = ({
  onClickOpenSearch,
  isCollapsed,
}: {
  onClickOpenSearch: () => void;
  isCollapsed: boolean;
}) => {
  return (
    <div className={styles.asideSearchInput} onClick={onClickOpenSearch}>
      {isCollapsed ? (
        <Tooltip
          color="navy"
          position="right"
          style={{ zIndex: 20 }}
          content={'Search'}
          trigger={
            <ButtonOutline size="sm">
              <FontAwesomeIcon icon={faSearch} />
            </ButtonOutline>
          }
        />
      ) : (
        <Input
          slots={{
            before: <FontAwesomeIcon icon={faSearch} width={16} />,
          }}
          transparent
          placeholder="Search any token"
          size="sm"
        />
      )}
    </div>
  );
};
