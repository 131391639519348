import { useEffect } from 'react';
import { DEFAULT_AVATAR, DEFAULT_AVATAR_ID, EItemCategory, EPIC_BEAN_NAME } from '@/components/beans/types';
import { UserItemResponseDto } from '@/services/index';
import useRewardsStore from '@/stores/rewardsStore';
import { api } from '@/utils/api';
import { useAuthToken } from './useAuth';

const useUserRewardsService = () => {
  const { setRewards } = useRewardsStore();

  const { isLoggedIn } = useAuthToken();

  useEffect(() => {
    if (isLoggedIn) {
      getUserRewards();
    }
  }, [isLoggedIn]);

  const getUserRewards = async () => {
    if (!isLoggedIn) {
      return;
    }
    // Get user close chests
    const chests = await api.user.userControllerGetUserChests();

    // Get user items
    const items = await api.user.userControllerGetUserItems();
    if (items && items.length > 0) DEFAULT_AVATAR.moneyId = items[0].moneyId;
    items.unshift(DEFAULT_AVATAR);

    // Set user avatar
    for (let i = 0; i < items.length; i++) {
      // Unequip default avatar
      if (items[i].itemId === DEFAULT_AVATAR_ID) {
        items[i].properties.isEquipped = false;
      }
    }

    let foundAvatar = false;
    let avatar: UserItemResponseDto = {} as UserItemResponseDto;
    for (const item of items) {
      if (item.category.toLowerCase() === EItemCategory.AVATAR && item.properties?.isEquipped) {
        avatar = item;
        foundAvatar = true;
        break;
      }
    }

    if (!foundAvatar) {
      // Equip default avatar
      for (let i = 0; i < items.length; i++) {
        if (items[i].itemId === DEFAULT_AVATAR_ID) {
          items[i].properties.isEquipped = true;
          avatar = items[i];
        }
      }
    }

    // Set epic beans
    let magicBeans: UserItemResponseDto = {} as UserItemResponseDto;
    for (const item of items) {
      if (
        item.category.toLowerCase() === EItemCategory.BEAN &&
        item.name.toLowerCase() === EPIC_BEAN_NAME.toLowerCase()
      ) {
        magicBeans = item;
        break;
      }
    }
    setRewards({
      avatar,
      chests,
      items,
      magicBeans,
    });
  };

  return { getUserRewards };
};

export default useUserRewardsService;
